<template>
  <el-card class="site-page-wrapper">
    <!-- <search-bar slot="header" @selectProject="handleSearchSites"></search-bar>
  
    <el-table :data="siteList" :height="tHeight">
      <el-table-column label="序号" type="index" align="center" width="60"></el-table-column>
      <el-table-column prop="siteName" label="站点名称" width="200"></el-table-column>
      <el-table-column prop="siteID" label="站点编号" width="150" align="center"></el-table-column>
      <el-table-column prop="siteAddress" label="站点详细地址" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="siteAltitude" label="海拔高度" width="100" align="center"></el-table-column>
      <el-table-column label="无人机编号" width="200" align="center">
        <template slot-scope="scope">
          <span>{{ getDroneInfor(scope.row) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="机库编号" width="200" align="center">
        <template slot-scope="scope">
          <span>{{ getHiveInfor(scope.row) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="siteMode" label="站点模式" width="200" align="center" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          {{ scope.row.siteMode | interpret }}
        </template>
      </el-table-column>
      <el-table-column prop="siteSFMode" width="100" label="飞行模式" align="center">
        <template slot-scope="scope">
          <el-tag size="mini" :type="scope.row.siteSFMode === 1 ? '' : 'warning'">{{ scope.row.siteSFMode === 1 ? '孤岛模式' : '跳棋模式' }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="创建时间" width="200" align="center"></el-table-column>
      <el-table-column label="操作" align="center" width="128">
        <template slot-scope="scope">

          <el-button  type="text" icon="el-icon-edit" @click="$router.push({ name: 'AddSite', query: { stId: scope.row.siteID } })">修改</el-button>
          <el-button  type="text" icon="el-icon-delete" style="color: #f40" @click="handleDelete(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-row class="pagination-bar">
      <el-pagination background layout="total, prev, pager, next" :current-page="page" :page-size="size" @current-change="handleCurrentChange" :total="total"></el-pagination>
    </el-row> -->
  </el-card>
</template>

<script>
import SearchBar from './component/SearchBar';
import sleep from '@/utils/sleep';
import { getSiteGroup, getSiteDetail, deleteSiteByStid } from '@/api/site';
import statusInclude from '@/utils/statusCode';

export default {
  components: {
    SearchBar,
  },
  data() {
    return {
      tHeight: 0,
      map: null,
      siteLocationMap: null,
      page: 1,
      total: 0,
      size: 13,
      socketInstance: null,
      dialogVisible: false,
      mapDialogVisible: false,
      SchedulingDialogVisible: false,
      expand: {
        uavState: 0,
        osdkState: 0,
        subState: 0,
        droneUpdate: 0,
        hiveState: 0,
        weather: 0,
        windSpeed: 0,
        isRaining: 0,
        hiveUpdate: 0,
      },
      siteList: [],
      siteDetail: {},
    };
  },
  filters: {
    interpret(mode) {
      const hiveModel = {
        1: '机库＋射频基站',
        2: '射频基站',
        3: '单兵中枢',
        4: '遥控器',
        5: '机载计算机',
      };
      return hiveModel[mode];
    },
  },
  created() {
    window.addEventListener('resize', this.getHeight);
    this.getHeight();
  },
  mounted() {
    this.getSourceSize(200).then((size) => {
      this.size = size;
      this.getSiteListData({ page: this.page, size: size });
    });
  },
  methods: {
    /**
     * @description 获取窗口高度
     * @return void
     */
    getHeight() {
      this.tHeight = this.tHeight = document.getElementsByTagName('body')[0].offsetHeight - this.$store.state.settings.offset;
    },

    handleSearchSites(PID) {
      this.getSiteListData({ PID }).then((siteData) => {
        if (siteData === '请求站点信息失败') return this.$message({ type: 'warning', message: siteData });
        this.siteData = siteData.map((e) => Object.assign(e, this.expand));
      });
    },

    //当前页改变时触发 跳转其他页
    handleCurrentChange(val) {
      this.page = val;
      this.getSiteListData({ page: this.page, size: this.size });
    },

    //删除站点信息
    handleDelete(site) {
      this.$confirm('删除此站点?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          deleteSiteByStid(site.siteID).then((res) => {
            if (statusInclude(res.code)) {
              this.$message({ type: 'success', message: '删除成功' });
              this.getSiteListData();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
    getDroneInfor(row) {
      if (row.UAVInfo && row.UAVInfo.UAVID) {
        return row.UAVInfo.UAVID;
      } else {
        return '';
      }
    },
    getHiveInfor(row) {
      if (row.hiveinfo && row.hiveinfo.hiveID) {
        return row.hiveinfo.hiveID;
      } else {
        return '';
      }
    },
    handleClose() {},
    handleMapClose() {
      this.mapDialogVisible = false;
    },
    mapRendered() {
      this.map = this.$refs.map;
      if (this.siteLocationMap) {
        this.map.addHiveMarker(null, null, this.siteLocationMap[0], this.siteLocationMap[1], true);
      }
    },
    posHandler(obj) {
      let { title, siteLocation } = obj;
      this.siteLocationMap = siteLocation;
      let siteMode = null;
      if (obj.siteMode) {
        siteMode = obj.siteMode;
      }
      let hiveModel = null;
      if (obj?.hiveInfo?.hiveModel) {
        hiveModel = obj.hiveInfo.hiveModel;
      }
      if (this.map) {
        this.map.addHiveMarker(siteMode, hiveModel, siteLocation[0], siteLocation[1], true);
      }
    },
    //表格高度请求所需要请求的数据size
    async getSourceSize(time) {
      await sleep(time);
      return Math.ceil(parseInt(document.getElementsByClassName('el-table__body-wrapper')[0].style.height) / 51);
    },

    //站点列表数据
    async getSiteListData({ page = 1, size = 13, PID = '' } = {}) {
      return new Promise(async (resolve, reject) => {
        const { code, data, reason } = await getSiteGroup(page, size, PID);
        if (!statusInclude(code)) {
          this.$message.error(`请求站点信息失败：${reason}`);
          return reject(new Error(`请求站点信息失败${reason}`));
        }
        const { total, list } = data;
        this.siteList = list;
        this.total = total;
      });
    },
    //请求站点详情
    async getSiteDetail(id) {
      const { code, data, reason } = await getSiteDetail(id);
      this.siteDetail = data;
      this.dialogVisible = true;
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getHeight);
  },
};
</script>

<style lang="scss" scoped>
.site-page-wrapper {
  background: #{$cardBg};
  position: relative;
  ::v-deep .el-card__header {
    background: #{$cardHeaderBg};
  }
  ::v-deep .el-card__body {
    height: calc(100vh - #{$offset});
    position: relative;
  }
}
</style>
