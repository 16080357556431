<template>
  <el-form class="site-search-bar-wrapper" :inline="true" size="small">
    <el-form-item label="站点名称">
      <el-select filterable clearable v-model="project" @change="selectProject">
        <el-option v-for="project in projectList" :key="project.projectId" :value="project.projectId" :label="project.projectName"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item style="float: right">
      <el-button  type="primary" @click="$router.push({ name: 'AddSite' })" style="margin-right: 0">新增站点</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { getProjectList } from '@/api/project.js';
export default {
  name: 'SearchBarInProject',
  data() {
    return {
      project: '',
      projectList: [],
    };
  },
  created() {
    this.getProjectList();
  },
  methods: {
    selectProject(PID) {
      console.log(PID);
      this.$emit('selectProject', PID);
    },
    async getProjectList() {
      const { code, data } = await getProjectList(1, 500);
      this.projectList = data?.records.map((project) => {
        return {
          projectId: project.pnum,
          projectName: project.pname,
        };
      });
    },
  },
};
</script>
